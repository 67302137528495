<template>
  <v-app-bar app color="#2196F3" dark elevate-on-scroll>
    <v-toolbar-title>
      <router-link to="/" class="header-logo">Folio Sentry</router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- Navigation Tabs -->
    <div class="tabs-container d-none d-md-flex">
      <v-btn
        v-for="(tab, index) in tabsList"
        :key="index"
        text
        class="headerButton"
        :class="{ activeTab: tabs === index }"
        @click="handleTabClick(index)"
      >
        {{ tab.label }}
      </v-btn>
    </div>

    <!-- Sign In Button -->
    <v-btn
      class="sign-in-button"
      @click="handleGoogleLogin"
    >
      <v-icon left>mdi-google</v-icon>
      Sign In
    </v-btn>
  </v-app-bar>

  <!-- Login Dialog -->
  <v-dialog v-model="loginDialog" max-width="400">
    <v-card>
      <v-card-title class="text-h5">Login</v-card-title>
      <v-card-text>
        <v-text-field label="Email" v-model="email" type="email"></v-text-field>
        <v-text-field label="Password" v-model="password" type="password"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="loginDialog = false">Cancel</v-btn>
        <v-btn color="primary" @click="handleLogin">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Mobile Navigation Drawer -->
  <v-navigation-drawer v-model="menu" app temporary right>
    <v-list>
      <v-list-item
        v-for="(tab, index) in tabsList"
        :key="index"
        @click="handleMenuItemClick(index)"
      >
        <v-icon class="menu-icon">mdi-circle-small</v-icon>
        <v-list-item-title>{{ tab.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'MyHeader',
  setup() {
    const tabs = ref(null);
    const router = useRouter();
    const menu = ref(false);
    const loginDialog = ref(false);
    const email = ref('');
    const password = ref('');


    const tabsList = [
      { label: 'Dashboard', route: '/dashboard' },
      { label: 'Financial Analysis', route: '/financial-data' },
      { label: 'Summary & News', route: '/investor-summary' },
      { label: 'Performance', route: '/performance' },
    ];

    const handleTabClick = (index) => {
      tabs.value = index;
      router.push(tabsList[index].route);
    };

    const handleMenuItemClick = (index) => {
      tabs.value = index;
      router.push(tabsList[index].route);
      menu.value = false;
    };

    const handleLogin = () => {
      console.log('Logging in with:', email.value, password.value);
      loginDialog.value = false;
    };

    const handleGoogleLogin = async () => {
      try {
        // Call the backend /login endpoint to get the authorization URL and state
        const response = await fetch('https://api.foliosentry.com/login');
        const data = await response.json();
        console.log("TTTREST", data.state)
        // Store the state in sessionStorage
        sessionStorage.setItem('oauth_state', data.state);

        // Redirect the user to Google's authorization URL
        window.location.href = data.authorization_url;
      } catch (error) {
        console.error('Error during Google login:', error);
      }
    };

    return {
      tabs,
      tabsList,
      handleTabClick,
      handleMenuItemClick,
      menu,
      loginDialog,
      email,
      password,
      handleLogin,
      handleGoogleLogin
    };
  },
};
</script>

<style scoped>
/* Header Button Style */
.headerButton {
  color: #ffffff !important;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 15px;
}
.headerButton:hover {
  color: #b2dfdb !important;
}

/* Logo */
.header-logo {
  color: #ffffff !important;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

/* App Bar */
.v-app-bar {
  background: linear-gradient(90deg, #004d40, #00695c);
}

/* Tab Container */
.tabs-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 10px;
}

/* Sign In Button */
.sign-in-button {
  background: white !important;
  color: #004d40 !important;
  border-radius: 20px;
  margin-right: 10px;
  text-transform: capitalize;
  font-weight: 500;
  transition: 0.3s ease;
  padding: 8px;
}
.sign-in-button:hover {
  background: #e0f2f1 !important;
}
</style>